import { lazy, Suspense, useMemo } from "react";
import ArrowClockwiseLoading from "../../../Components/ResponsGraphic/loading/loading";

export default function ModulImportGallery({ id, modulPath, className, play, galleryDataArray, alignImporten }) {
	const Component = useMemo(() => {
		if (modulPath && className) {
			const modelImportRes = import(`../${modulPath}`).catch((error) => {
				console.error("Failed to load ImageGallery:", error);
				//mandatory return
				return { default: () => <div className='loadin-error'>Error loading the component.</div> };
			});
			if (modelImportRes) return lazy(() => modelImportRes);
		}
		return null;
	}, [modulPath, className]);

	return (
		<div className={`${className} modul-import`} id={id}>
			{Component && (
				<Suspense
					fallback={
						<div className='loading'>
							<ArrowClockwiseLoading />
						</div>
					}
				>
					<Component play={play} galleryDataArray={galleryDataArray} alignImporten={alignImporten} />
				</Suspense>
			)}
		</div>
	);
}
