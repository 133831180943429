import { NavLink } from "react-router-dom";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { TechnologyList } from "../Technology/TechnologyList";
import { IsActivePanel } from "./IsActivePanel";
import UseModelImportGallery from "../LazyImport/useModelImportGallery";
import UseModulImportArticle from "../LazyImport/useModelImportArticle";
import UseModulImport from "../../../Hooks/useModulImport";

export const Panel = ({ sectionData, activePanel, mediaIsDisktop }) => {
	// comstum hook
	let [thisActivePanel, setThisActivePanel] = useState(false);
	useEffect(() => {
		setThisActivePanel(IsActivePanel(activePanel, sectionData.panelId));
	}, [activePanel, sectionData.panelId]);

	return (
		<section
			className={`${sectionData.class} panel panel-link panel-wrapper-level2 padding-to-top ${sectionData.namedId}`}
		>
			{sectionData.importBackgroundModulArray && (
				<div className='panel-background-wrapper'>
					{sectionData.importBackgroundModulArray.map((modul, index) => {
						return (
							<UseModelImportGallery
								id={modul.id}
								modulPath={modul.path}
								className={modul.className}
								key={modul.className + index}
								galleryDataArray={modul.galleryDataArray}
								play={thisActivePanel}
								alignImporten={"left"}
							/>
						);
					})}
				</div>
			)}
			<div className='panel-section-wrapper'>
				{sectionData.animationWrapper && (
					<div className='animation-wrapper'>
						{/* e.g. Birds */}
						<UseModelImportGallery
							id={sectionData.animationWrapper.name}
							modulPath={sectionData.animationWrapper.path}
							className={sectionData.animationWrapper.name}
							key={sectionData.animationWrapper.name}
						/>
					</div>
				)}
				<div className='panel-section-wrapper__one-two'>
					{sectionData.importArrowDown && (
						<UseModulImport
							folderName={"arrowDown"}
							componentName={sectionData.importArrowDown.componentName}
							className={sectionData.importArrowDown.className}
						/>
					)}

					{/* left side  */}

					<div className='panel-section-wrapper__one-two__section-one'>
						{sectionData.article && (
							<UseModulImportArticle
								modulPath={`Panels/${sectionData.article.modulName}`}
								articleData={sectionData.article}
								articleClassName='panel-article'
							/>
						)}
					</div>

					{/* right side  */}
					<div className='panel-section-wrapper__one-two__section-two'>
						<section className='explanation'>
							{sectionData.toolTipList.panelArticle && (
								<UseModelImportGallery
									modulPath={sectionData.toolTipList.panelArticle.importToolTipHeader.path}
									className={sectionData.toolTipList.panelArticle.importToolTipHeader.name}
									key={sectionData.toolTipList.panelArticle.importToolTipHeader.name}
								/>
							)}
							{/* {explanation article} */}
							<article>
								{sectionData.explanation && sectionData.explanation.header && <h2>{parse(sectionData.explanation.header)}</h2>}
								{sectionData.explanation && sectionData.explanation.p && <p>{parse(sectionData.explanation.p)}</p>}
								{/* Link  */}
								{sectionData.pageLink && (
									<NavLink to={sectionData.pageLink} className='link-to btn btn-primary mb-2'>
										{sectionData.linkText}
									</NavLink>
								)}
							</article>
							{/* {ToolTips header} */}
							{sectionData.toolTipList && sectionData.toolTipList.header && (
								<h3 className='explanation__technology'>{sectionData.toolTipList.header} </h3>
							)}
							<TechnologyList
								Technology={sectionData.toolTipList}
								thisActivePanel={thisActivePanel}
								mediaIsDisktop={mediaIsDisktop}
							/>

							{/* contact information */}
							{sectionData.contactInformation && (
								<div className='link-to'>
									<h3>{sectionData.contactInformation.header}</h3>
									<a className='btn btn-primary' href={`mailto:${sectionData.contactInformation.eMailAdress}`}>
										{sectionData.contactInformation.eMailAdress}
									</a>
								</div>
							)}
						</section>
					</div>
				</div>
			</div>
			{/* {children} */}
		</section>
	);
};
