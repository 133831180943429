import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

// import "../src/pages/Home/main.scss";
import "../src/Pages/home/main.scss";
import "./_variables.scss";
import "./App.scss";
import "./fonts/fontFace.css";
//import { Naviagtion } from "./Naviagtion/navigation";
// test git 3
import { Router } from "./router/router";

export default function App({ mediaBreakPoint }) {
	// let body = document.querySelector("body");
	// useEffect(() => {
	// 	console.log(body.style.paddingRight);
	// 	console.log(body.style.overflow);
	// });

	useGSAP(
		() => {
			const body = document.querySelector("body");
			// create
			let mm = gsap.matchMedia(),
				breakPoint = mediaBreakPoint;
			mm.add(
				{
					// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
					isDesktop: `(min-width: ${breakPoint}px)`,
					isMobile: `(max-width: ${breakPoint - 1}px)`,
				},
				(context) => {
					// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
					let { isDesktop, isMobile } = context.conditions;
					//if (panels) {
					if (isDesktop) {
						body.classList.add("disktop");
						body.classList.remove("mobil");
						//	}
					} else if (isMobile) {
						if (body.classList.contains("disktop")) {
							body.classList.remove("disktop");
						}
						body.classList.add("mobil");
					}
					return () => {
						// optionally return a cleanup function that will be called when none of the conditions match anymore (after having matched)
						// it'll automatically call context.revert() - do NOT do that here . Only put custom cleanup code here.
					};
				}
			);
			return () => {
				mm.revert();
			};
		}
		// { scope: wrapper }
	);

	return (
		<div className='App'>
			<Router mediaBreakPoint={mediaBreakPoint} />
		</div>
	);
}
