import { lazy, Suspense, useMemo } from "react";
import ArrowClockwiseLoading from "../Components/ResponsGraphic/loading/loading";

export default function UseModulImport({ folderName, componentName, className, id, play }) {
	const Component = useMemo(() => {
		if (folderName && className && componentName) {
			const path = `../Components/${folderName}/${componentName}`;
			const modelImportRes = import(`../Components/${folderName}/${componentName}`).catch((error) => {
				console.error("Failed to load ImageGallery:", error);
				//mandatory return
				return { default: () => <div className='loadin-error'>Error loading the component.</div> };
			});
			if (modelImportRes) return lazy(() => modelImportRes);
		}
		return null;
	}, [folderName, className, componentName]);

	return (
		<div className={`${className} modul-import`} id={id}>
			{Component && (
				<Suspense
					fallback={
						<div className='loading'>
							<ArrowClockwiseLoading />
						</div>
					}
				>
					<Component play={play} id={id} />
				</Suspense>
			)}
		</div>
	);
}
