import { ArrowClockwise } from "react-bootstrap-icons";
import "./loading.scss";

export default function ArrowClockwiseLoading() {
	return (
		<div className='loading-wrapper'>
			<div className='loading-inner-wrapper'>
				<ArrowClockwise className='loading-arrow-bootstrap-logo' />
			</div>
		</div>
	);
}
