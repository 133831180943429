import { lazy, Suspense, useMemo } from "react";
import ArrowClockwiseLoading from "../../../Components/ResponsGraphic/loading/loading";

export default function ModulImport({ modulPath, modulClassName, articleClassName, articleData, id }) {
	const Component = useMemo(() => {
		if (modulPath) {
			const modelImportRes = import(`../${modulPath}`).catch((error) => {
				console.error("Failed to load component:", error);
				//mandatory return
				return { default: () => <div className='loadin-error'>Error loading the component.</div> };
			});
			if (modelImportRes) return lazy(() => modelImportRes);
		}
		return null;
	}, [modulPath]);

	return (
		<div className={`modul-import${modulClassName ? " " + modulClassName : ""}`} id={id}>
			{Component && (
				<Suspense fallback={<ArrowClockwiseLoading />}>
					<Component articleData={articleData} articleClassName={articleClassName} />
				</Suspense>
			)}
		</div>
	);
}
