import "./home.scss";
import { useRef, useState } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
//
import { PagePagination } from "./pagePagination";
import { Bug } from "react-bootstrap-icons";
import ArrowClockwiseLoading from "../../Components/ResponsGraphic/loading/loading";
import { Panel } from "./Panels/Panel";
// import data
import { useFetch } from "../../Hooks/useFetch";
// pageId
import { PageProperties } from "../pagesProperties/pagesProperties";
// gsap register
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export const Home = (ref) => {
	const { id, title } = ref;
	PageProperties({ title: title, id: id });
	// DOM ref
	const main = useRef();
	const wrapper = useRef();
	const panelWrapperRef = useRef();
	const refPanelWrapper = useRef();
	// data
	// const { data: panelData, isPending, error } = useFetch("http://localhost:3000/db/db.json");
	const { data: panelData, isPending, error } = useFetch("https://web-integrator-net.dk/db/db.json");

	// Pagination
	let [pageNumber, setPageNumber] = useState(1);
	// click on pagination get index
	const [onClickGetIndex, setOnClickGetIndex] = useState();

	useGSAP(() => {
		if (onClickGetIndex !== undefined) {
			let index = onClickGetIndex.selected;
			let scrollTo = index * window.innerHeight;

			gsap.to(window, {
				scrollTo: { y: scrollTo, autoKill: false },
				duration: 0.4,
				onComplete: () => {},
				overwrite: true,
			});
		}
	}, [onClickGetIndex]);

	// active panel
	const [activePanel, setActivePanel] = useState(1);
	// mediea query
	// IsDisktop
	const [mediaIsDisktop, setMediaDisktop] = useState(true);

	useGSAP(
		() => {
			const body = document.querySelector("body");
			// create
			let mm = gsap.matchMedia(),
				breakPoint = 900;
			mm.add(
				{
					// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
					isDesktop: `(min-width: ${breakPoint}px)`,
					isMobile: `(max-width: ${breakPoint - 1}px)`,
				},
				(context) => {
					// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
					let { isDesktop, isMobile } = context.conditions;
					//setMedia(contextDisktop.conditions);
					//if (panels) {
					if (isDesktop) {
						setMediaDisktop(true);
						body.classList.add("disktop");
						body.classList.remove("mobil");
						//	}
					} else if (isMobile) {
						setMediaDisktop(false);
						if (body.classList.contains("disktop")) {
							body.classList.remove("disktop");
						}
						body.classList.add("mobil");
					}
					return () => {
						// optionally return a cleanup function that will be called when none of the conditions match anymore (after having matched)
						// it'll automatically call context.revert() - do NOT do that here . Only put custom cleanup code here.
					};
				}
			);
			return () => {
				mm.revert();
			};
		},
		{ scope: wrapper }
	);
	// scrollTrigger
	const thisScrollTriggerRef = useRef();
	useGSAP(
		() => {
			// if we have data it is safe to do GSAP
			if (panelData) {
				const panels = gsap.utils.toArray(".panel");
				const firstPanel = panels[0];
				const lastPanel = panels[panels.length - 1];
				const snapIncrement = 1 / (panels.length - 1);

				if (!mediaIsDisktop) {
					setActivePanel(undefined);
				} else if (mediaIsDisktop) {
					setPageNumber(1);
					setActivePanel(1);
					thisScrollTriggerRef.current = ScrollTrigger.create({
						trigger: firstPanel,
						start: "top top",
						endTrigger: lastPanel,
						end: "bottom bottom",

						snap: {
							snapTo: 1 / (panels.length - 1),
							duration: { min: 0.25, max: 0.75 }, // the snap animation should be at least 0.25 seconds, but no more than 0.75 seconds (determined by velocity)
							delay: 0.125, // wait 0.125 seconds from the last scroll event before doing the snapping
							ease: "power1.inOut", // the ease of the snap animation ("power3" by default),
						},
						onUpdate(e) {
							let i = Math.round(e.progress / snapIncrement);
							setPageNumber(i + 1);
							setActivePanel(i + 1);
						},
					});
				}

				// remove all ScrollTrigger
				if (!mediaIsDisktop) {
					ScrollTrigger.getAll().forEach((scrollTrigger) => {
						scrollTrigger.kill();
					});
				}
			}
		},
		{ dependencies: [panelData, mediaIsDisktop], scope: refPanelWrapper.current }
	);

	return (
		<div ref={wrapper} className='home-wrapper back-ground-black'>
			<header className='header-intern-nav'>
				{panelData && (
					<PagePagination
						data_panels={panelData.data_panels}
						setOnClickGetIndex={setOnClickGetIndex}
						pageNumber={pageNumber}
					></PagePagination>
				)}
			</header>

			<main ref={main} className='main'>
				<div className='home-article-panel-wrapper panel-wrapper-level1' ref={refPanelWrapper}>
					{error && (
						<div className='error'>
							<h2>{error}</h2>
							<Bug className='bug-bootstrap-logo' />
						</div>
					)}
					{isPending && <ArrowClockwiseLoading />}
					<div ref={panelWrapperRef}>
						{panelData &&
							panelData.data_panels.map((thisData) => {
								return (
									<Panel
										sectionData={thisData}
										activePanel={activePanel}
										key={thisData.id}
										mediaIsDisktop={mediaIsDisktop}
									></Panel>
								);
							})}
					</div>
				</div>
			</main>
		</div>
	);
};
