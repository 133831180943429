export const IsActivePanel = (activePanel, panelId) => {
	let thisActivePanel;
	// mobil
	if (activePanel === undefined) {
		thisActivePanel = true;
	} else {
		if (activePanel == panelId) {
			thisActivePanel = true;
		} else {
			thisActivePanel = false;
		}
	}

	return thisActivePanel;
};
